




















import { Component, Vue } from 'vue-property-decorator';
import { ArticleId } from '@/api/api';
@Component({
    name: 'BusinessConsultingDetails',
    components: {}
})
export default class Login extends Vue {
    private mounted() {
        const { ArticleId } = this.$route.query;
        this.init(ArticleId);
    }

    private async init(id) {
        try {
            const { Data } = await ArticleId(id);
            this.draw(Data);
        } catch (error) {
            console.warn(error);
        }
    }

    private async draw(Data) {
        try {
            const article: any = document.getElementById('articleb');
            article.innerHTML = '';
            article.appendChild(this.parseDom(Data.ArticleBody));
        } catch (error) {
            console.warn(error);
        }
    }

    private parseDom(arg: string) {
        const objE = document.createElement('div');
        objE.innerHTML = arg;
        return objE;
    }
}
