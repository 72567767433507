






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'BusinessConsultingList',
    components: {}
})
export default class BusinessConsultingList extends Vue {
    @Prop({
        type: Array,
        default: () => []
    })
    public dataList: any;

    public jump(item) {
        this.$router.push({
            name: 'BusinessConsultingDetails',
            query: {
                ArticleId: item.ArticleId
            }
        });
    }
}
