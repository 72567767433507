



























import { Component, Ref, Vue } from 'vue-property-decorator';

import ColumnTypeCard from '@/components/PortalSite/ColumnTypeCard/index.vue';
import YwhzpPgination from '@/components/PortalSite/PaginationCard/pagination.vue';
import SkeletonList from './component/skeleton-list.vue';
import BusinessConsultingList from './component/business-consulting-list.vue';

import { searchConditionalTypeEnum } from '@/utils/type-enum';
import { ArticleGetList } from '@/api/api';
@Component({
    name: 'BusinessConsulting',
    components: {
        ColumnTypeCard,
        YwhzpPgination,
        SkeletonList,
        BusinessConsultingList
    }
})
export default class BusinessConsulting extends Vue {
    @Ref('pgination')
    private readonly pginationRef!: any;

    public loading: boolean = false;

    public dataList: any = [];

    public activeIndex: any = '';

    public pageParams: any = {
        PageIndex: 1,
        PageSize: 10,
        total: 0,
        Expressionable: []
    };

    private async init() {
        try {
            this.loading = true;
            if (this.activeIndex) {
                this.pageParams.Expressionable = JSON.stringify([{ FieldName: 'CategoryId', FieldValue: this.activeIndex, ConditionalType: searchConditionalTypeEnum['等于'] }]);
            }
            const { DataList, Total, PageSize } = await ArticleGetList(this.pageParams);
            this.dataList = DataList;
            this.pageParams.PageSize = PageSize;
            this.pageParams.total = Total;
        } catch (error) {
            console.warn(error);
        } finally {
            this.loading = false;
        }
    }

    public currentChange(size) {
        this.pageParams.PageIndex = size;
        this.init();
    }

    public change(id) {
        this.activeIndex = id;
        this.init();
    }
}
