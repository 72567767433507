





















import { Component, Vue } from 'vue-property-decorator';

import { ArticleGetList, ArticleId } from '@/api/api';
import { searchConditionalTypeEnum } from '@/utils/type-enum';

@Component({
    name: 'EnterpriseOverview',
    components: {}
})
export default class EnterpriseOverview extends Vue {
    public src1: any = '';
    public src2: any = '';

    public pageParams: any = {
        PageIndex: 1,
        PageSize: 4,
        Expressionable: []
    };

    private mounted() {
        this.init();
        this.init2();
    }

    private async init() {
        try {
            this.pageParams.Expressionable = JSON.stringify([
                { FieldName: 'CategoryId', FieldValue: 27, ConditionalType: searchConditionalTypeEnum['等于'] }
            ]);
            const { DataList } = await ArticleGetList(this.pageParams);
            if (DataList.length) {
                const { Data } = await ArticleId(DataList[0].ArticleId);
                this.draw(Data, 'gsjj');
                this.src1 = Data.ImagePatch;
            }
        } catch (error) {
            console.warn(error);
        }
    }

    private async init2() {
        try {
            this.pageParams.Expressionable = JSON.stringify([
                { FieldName: 'CategoryId', FieldValue: 28, ConditionalType: searchConditionalTypeEnum['等于'] }
            ]);
            const { DataList } = await ArticleGetList(this.pageParams);
            if (DataList.length) {
                const { Data } = await ArticleId(DataList[0].ArticleId);
                this.draw(Data, 'dizhi');
                this.src2 = Data.ImagePatch;
            }
        } catch (error) {
            console.warn(error);
        }
    }

    private draw(heml, id) {
        const article: any = document.getElementById(id);
        article.innerHTML = '';
        article.appendChild(this.parseDom(heml.ArticleBody));
    }

    private parseDom(arg: string) {
        const objE = document.createElement('div');
        objE.innerHTML = arg;
        return objE;
    }
}
