










import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'Pagination',
    components: {}
})
export default class Pagination extends Vue {
    @Prop({
        type: Object,
        default: {}
    })
    public pagination: any;

    public currentChange(size) {
        this.$emit('currentChange', size);
    }
}
