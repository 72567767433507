














import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'SkeletonList',
    components: {}
})
export default class SkeletonList extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    public loading: boolean;
}
